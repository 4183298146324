<template>
  <div class="box">
    <div class="tabs">
      <div id="tab2_tab1">
        <div class="tab2-top">
          <Btn
            v-for="(item, index) in orgList"
            :key="index"
            :name="item.depart_name"
            :info="item"
            @getOrgInfo="getOrg2Info"
          />
        </div>
        <div class="tab2-top" v-if="org2Info">
          <Btn
            v-for="(item, index) in org2Info"
            :key="index"
            :name="item.depart_name"
            :info="item"
            @getOrgInfo="getOrg3Info"
            :customStyle="btnCustomStyle"
          />
        </div>
        <div class="tab2-top" v-if="org3Info">
          <Btn
            v-for="(item, index) in org3Info"
            :key="index"
            v-show="getFundInfoShowId == null || getFundInfoShowId === item.id"
            :name="item.depart_name"
            :info="item"
            @getOrgInfo="getFundInfo"
            :customStyle="getFundInfoId === item.id ? btnCustomStyle : {}"
          />
        </div>
      </div>

      <div class="org-child" v-if="showOrgInfo">
        <Tab
          :list="tabList"
          :active="tabActive"
          :customStyle="{
            border: '1px solid #ccc',
            borderBottom: 'none',
            fontSize: '',
          }"
          @tabChange="tabChange"
        />
        <IndexDepInterest
          v-if="tabActive == 0"
          :id="getFundInfoId"
          :departId="getFundInfoCode"
          :sign="sign"
        />
        <IndexDepObject
          v-if="tabActive == 1"
          :id="getFundInfoId"
          :departId="getFundInfoCode"
          :sign="sign"
        />
        <IndexDepRectify
          v-if="tabActive == 2"
          :id="getFundInfoId"
          :name="getFundInfoName"
          :sign="sign"
        />
        <IndexDepProject
          v-if="tabActive == 3"
          :id="getFundInfoCode"
          :departId="getFundInfoCode"
        />
        <IndexInvolvingRights
          v-if="tabActive == 4"
          :id="getFundInfoId"
          :departId="getFundInfoCode"
          :sign="sign"
        />
      </div>
    </div>
    <VoicePlay :content="ZY_writtenWords" />
  </div>
</template>
<script>
import BASE from "../../../utils/base";

import Btn from "../components/Btn";
import Tab from "../components/Tab";

import IndexDepInterest from "./indexDepInterest";
import IndexDepObject from "./indexDepObject";
import IndexDepRectify from "./indexDepRectify";
import IndexDepProject from "./indexDepProject";
import IndexInvolvingRights from "./indexInvolvingRights";
import VoicePlay from "@/components/VoicePlay";

export default {
  name: "indexDep",
  components: {
    Btn,
    Tab,
    IndexDepInterest,
    IndexDepObject,
    IndexDepRectify,
    IndexDepProject,
    IndexInvolvingRights,
    VoicePlay,
  },
  activated() {},
  created() {
    console.log("indexDep -- created");
    this.getOrg(" ");
  },
  props: {
    sign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      btnCustomStyle: { background: "#ccc", color: "#b51414" },

      // 一级
      orgList: [],
      // 二级
      org2Info: [],
      // 三级
      org3Info: [],
      // 标签页
      tabList: [
        { name: this.$t('supervise.level2TabList.interest') },
        { name: this.$t('supervise.level2TabList.object') },
        { name: this.$t('supervise.level2TabList.rectify') },
        { name: this.$t('supervise.level2TabList.project') },
        { name: this.$t('supervise.level2TabList.involvingRights') },
      ],
      // 当前选中标签页
      tabActive: 0,

      showOrgInfo: false,

      getFundInfoId: "",
      getFundInfoCode: "",
      getFundInfoName: "",
      // 语音播报组件征用字段
      ZY_writtenWords: "",
    };
  },
  methods: {
    /**
     * 区域查询 - 一级
     */
    getOrg(data) {
      let that = this;
      this.axios
        .get(
          BASE.genUrl("zxSuperviseComm/getDepart", {
            orgcode: "6523",
            parentid: data,
          })
        )
        .then((res) => {
          console.log("组织机构查询", res);
          let data = res.data;
          console.log(that);
          if (data.result) {
            that.org2Info = [];
            that.org3Info = [];
            that.orgList = data.result;
            that.getOrg2Info(data.result[0]);
          }
        });
    },
    /**
     * 区域查询 - 二级
     */
    getOrg2Info(info, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = info.depart_name;

      const oid = info.id;
      let that = this;
      this.axios
        .get(
          BASE.genUrl("zxSuperviseComm/getDepart", {
            orgcode: "6523",
            parentid: oid,
          })
        )
        .then((res) => {
          console.log("组织机构2级查询", res);
          let data = res.data;
          if (data.result) {
            that.org3Info = [];
            that.org2Info = data.result;
            that.getOrg3Info(data.result[0]);
          }
        });
    },
    /**
     * 区域查询 - 三级
     */
    getOrg3Info(info, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = info.depart_name;

      const oid = info.id;
      const dept = info.depart_name;

      let that = this;
      this.deptName = dept;
      this.axios
        .get(
          // BASE.genUrl("zxSuperviseComm/getDepartNo", {
          BASE.genUrl("zxSuperviseComm/getDepartNoPower", {
            orgcode: "6523",
            parentid: oid,
          })
        )
        .then((res) => {
          console.log("组织机构3级查询", res);
          let data = res.data;
          if (data.result) {
            that.org3Info = data.result;
          }
        });
    },
    // 点击按钮回调事件区域开始
    getOrgInfo(item) {
      console.log("一级按钮", item);
    },
    // getOrg3Info (item) {
    //   console.log('二级按钮', item)
    // },
    getFundInfo(item, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = item.depart_name;

      this.getFundInfoId = item.id;
      this.getFundInfoCode = item.org_code;
      this.getFundInfoName = item.depart_name;
      this.showOrgInfo = true;
      console.log("三级按钮", item);
    },
    // 点击按钮回调事件区域结束

    /**
     * 切换分页
     */
    tabChange(index) {
      // 添加公共语音播报
      this.ZY_writtenWords = this.tabList[index].name;
      this.tabActive = index;
    },
  },
};
</script>
<style scoped>
.box {
}
.tabs {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
#tab2_tab1 {
  display: block;
}
.tab2-top {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

/* 二级样式 */
.tab2-top .org3Info {
  background: #ccc;
  color: #b51414;
}

/* 按钮样式 */
.item {
  flex: 0 0 32%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;

  font-size: 12px;
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.org-child {
  margin-top: 0.5rem;
}
</style>
